import { types } from "../types/types";

const initial = {
  proveedorInfo: {},
};

export const proveedorInfoReducer = (state = initial, action) => {
  switch (action.type) {
    case types.storeProveedorInfo:
      return {
        ...state,
        proveedorInfo: action.payload,
      };
    default:
      return state;
  }
};
