import { types } from "../types/types";

const init = {
  loginErr: null,
};

export const authErrReducer = (state = init, action) => {
  switch (action.type) {
    case types.setLoginErr:
      return {
        ...state,
        loginErr: action.payload,
      };

    default:
      return state;
  }
};
