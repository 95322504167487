import { useSelector } from "react-redux";
import CardPage from "../../../../layout/CardPage";
import { DibujaMapa } from "./DibujaMapa.jsx";

export const Mapa = () => {
  const {
    latOrigen,
    lngOrigen,
    latDestino,
    lngDestino,
    direccionOrigen,
    direccionDestino,
  } = useSelector((state) => state.asistenciasReducer.asistencia);

  if (!latOrigen || !lngOrigen) return <></>;

  return (
    <CardPage titulo="Mapa" style={{ height: 450 }}>
      <DibujaMapa
        pinOrigen={[lngOrigen, latOrigen]}
        direccionOrigen={direccionOrigen}
        pinDestino={[lngDestino, latDestino]}
        direccionDestino={direccionDestino}
      />
    </CardPage>
  );
};
