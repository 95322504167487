import { AsistenciasVivas } from "./AsistenciasVivas/AsistenciasVivas";
import { AsistenciasPorConfirmar } from "./AsistenciasPorConfirmar/AsistenciasPorConfirmar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetProveedorInfo } from "../../context/actions/proveedorInfoActions";
import { verificaLogin } from "../../context/actions/loginActions";

export const Home2 = () => {
  const dispatch = useDispatch();

  const { proveedorID } = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    dispatch(GetProveedorInfo());
  }, []);

  return (
    <>
      {/* <ProveedorInfo /> */}
      <AsistenciasPorConfirmar />
      <AsistenciasVivas />
    </>
  );
};
