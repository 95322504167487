import { Button } from "antd";
import React from "react";
import { ModalCargaComplemento } from "./_ModalCargaComplemento";
import { CloudUploadOutlined } from "@ant-design/icons";

export const Complemento = ({ orden }) => {
  const [open, setOpen] = React.useState(false);

  if (!orden.pagoUri) return <></>;
  return (
    <div style={{ textAlign: "center" }}>
      <Button danger size="small" onClick={() => setOpen(true)} icon={<CloudUploadOutlined />}>
         complemento
      </Button>
      <ModalCargaComplemento open={open} setOpen={setOpen} orden={orden}/>
    </div>
  );
};
