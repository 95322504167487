import { Select } from "antd";
import { useSelector } from "react-redux";
const { Option } = Select;

export const SelectSucursales = ({ label, value, onChange }) => {
  const { sucursales } =
    useSelector((state) => state.proveedorInfoReducer.proveedorInfo) || [];
  return (
    <>
      <div className="labelImput">{label}</div>
      <Select
        value={value}
        name="servicioID"
        onChange={onChange}
        style={{ width: "100%" }}
      >
        {sucursales.map((a, i) => (
          <Option key={i} value={a.proveedorSucursalID}>
            {a.nombre}
          </Option>
        ))}
      </Select>
    </>
  );
};
