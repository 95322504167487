import React from "react";
import { Redirect, Route } from "react-router-dom";
import { consLogged } from "../constants/consLogged";
import { VersionFront } from "./VersionFront";

////////////////////////////////////////////////////
export default function PrivateRoute({
  isAuthenticated,
  component: Component,
  ...rest
}) {
  ////////////////////////////////////////////////////
  return (
    <>
      <Route
        {...rest}
        component={(props) =>
          isAuthenticated === consLogged.LOGGED ? (
            <>
              <VersionFront />
              <Component {...props} />
            </>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </>
  );
}
