export const MAPBOX_TOKEN =
  "pk.eyJ1IjoicGF0OTAwMCIsImEiOiJjbDNhaXRnbzEwMXk1M2Vwa3F0MHFrNHFkIn0.1Y9DYByZSC-BkeaKFHpMZw";

export const PROVEEDORES_FACTURACION = ["uIXYHYwoLS", "mF5RIfTs3c"]; // ave, ibarra

export const opcionesDesgloseCarretero = [
  { value: "Banderazo", label: "Banderazo" },
  { value: "Costo x kilómetro", label: "Costo x kilómetro" },
  { value: "Casetas", label: "Casetas" },
  { value: "Maniobras", label: "Maniobras" },
  { value: "Acondicionamiento", label: "Acondicionamiento" },
  { value: "Otro", label: "Otro" },

];
