import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useLayoutEffect, useRef } from "react";
import { MAPBOX_TOKEN } from "../../../../constants/cons";

mapboxgl.accessToken = MAPBOX_TOKEN;

export const DibujaMapa = ({
  pinOrigen,
  direccionOrigen,
  pinDestino,
  direccionDestino,
}) => {
  const mapProveedores = useRef(null);
  const mapContainerP = useRef(null);
  const markerP = useRef(null);

  useLayoutEffect(() => {
    if (mapProveedores.current) return; // initialize map only once

    mapProveedores.current = new mapboxgl.Map({
      controls: true,
      container: mapContainerP.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: pinOrigen,
      zoom: 14,
    });
    mapProveedores.current.addControl(new mapboxgl.NavigationControl());

    //// Origen Pin
    markerP.current = new mapboxgl.Marker({})
      .setLngLat(pinOrigen)
      .setPopup(
        new mapboxgl.Popup().setHTML(`
<h1 style="color: red">Origen</h1>
    <h3>${direccionOrigen}</h3>
    `)
      )
      .addTo(mapProveedores.current);

    if (pinDestino != "" && pinDestino != null && pinDestino.length > 1) {
      if (pinDestino[0] != "" && pinDestino[1] != "") {
        markerP.current = new mapboxgl.Marker({})
          .setLngLat(pinDestino)
          .setPopup(
            new mapboxgl.Popup().setHTML(`
          <h1 style="color: teal">Destino</h1>
              <h3>${direccionDestino}</h3>
              `)
          )
          .addTo(mapProveedores.current);

        mapProveedores.current.fitBounds([pinDestino, pinOrigen], {
          padding: { top: 70, bottom: 50, left: 50, right: 70 },
        });
      }
    }
  });

  return <div ref={mapContainerP} style={styles.mapContainer}></div>;
};

const styles = {
  mapContainer: {
    height: 380,
    width: "100%",
    marginBottom: 10,
    float: "right",
    zIndex: 999,
  },
};
