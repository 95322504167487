import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { TituloModal } from "../../../../components/tituloModal/TituloModal";
import { ContenidoModal } from "./_ContenidoModal";
import { InfoCircleOutlined } from "@ant-design/icons";

export const ModalOrdenPago = ({ ordenPagoID }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title="Ver  Orden de Pago">
        <InfoCircleOutlined
          onClick={() => setOpen(true)}
          className="eyeIcon"
          style={{ color: "orange", fontSize: 18 }}
        />
      </Tooltip>

      <Modal
        width={"80%"}
        footer={[
          <Button key={1} onClick={() => setOpen(false)} size="small" danger>
            cerrar
          </Button>,
        ]}
        title={<TituloModal texto="Orden de Pago" />}
        open={open}
        onCancel={() => setOpen(false)}
      >
        {open && <ContenidoModal ordenPagoID={ordenPagoID} />}
      </Modal>
    </>
  );
};
