import { CloudDownloadOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { startGetArchivo } from "../../../../context/actions/asistenciasActions";

export const ListadoArchivos = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const { archivos } = useSelector((state) => state.asistenciasReducer);

  const descarga = (nombreArchivo) => {
    dispatch(startGetArchivo(asistenciaID, nombreArchivo));
  };
  return (
    <div>
      <div className="subtituloMenos">Archivos</div>
      <div style={{ height: 150, overflowY: "auto" }}>
        {archivos.map((a, i) => (
          <Row key={i} onClick={() => descarga(a)} className="rowArchivos">
            <Col>
              <CloudDownloadOutlined
                style={{
                  marginTop: 2,
                  marginLeft: 10,
                  marginRight: 10,
                  fontSize: 15,
                }}
              />
            </Col>
            <Col> {a}</Col>
          </Row>
        ))}
        <div>
          Le recordamos que, para que su servicio entre
          en proceso de pago, debe contener
          las evidencias de placas y cofirmación
          de servicio
        </div>
      </div>
    </div>
  );
};
