import { Input } from "antd";
import React from "react";

export default function LabelNumber({
  label,
  name,
  onChange,
  value,
  placeholder,
  disabled,
}) {
  return (
    <>
      <div className="labelImput">{label}</div>
      <Input
        type="number"
        disabled={disabled}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </>
  );
}
