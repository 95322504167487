import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { formatCurrency } from "../../../../utils/formatCurrency";

export const ListaItems = ({ arrayFull, eliminar }) => {
  //
  return arrayFull.map((item, index) => (
    <Row key={index} gutter={20} className="rowItemElementoSmall">
      <Col span={16}>{item.tipoCosto}</Col>
      <Col span={6} style={{ textAlign: "right" }}>
        {formatCurrency(item.costo)}
      </Col>
      <Col span={2}>
        <CloseCircleOutlined
          onClick={() => eliminar(index)}
          className="eyeIcon"
          style={{ color: "orange" }}
        />
      </Col>
    </Row>
  ));
};
