import axios from "axios";
import { HDI_URL, URL_BASE } from "../../constants/url";


export const axiosClientSigsa = axios.create({
  baseURL: `${URL_BASE}/api`
})


const getVerionCurrent = async () => {

  const result = await axiosClientSigsa('ver/getFrontProveedoresVer')
  return result.data
}


// 401 Unauthorized


axiosClientSigsa.interceptors.request.use(function (config) {
  // Haz algo antes que la petición se ha enviada
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // Haz algo con el error de la petición
  console.log({error})
  return Promise.reject(error);
});


axiosClientSigsa.interceptors.response.use(function (response) {
  // Haz algo con la respuesta de los datos
  return response;
}, function (error) {
  // Haz algo con el error
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  }
  return Promise.reject(error);
});


export const hdiClient = axios.create({
  baseURL: HDI_URL
})


hdiClient.interceptors.request.use(function (config) {
  // Haz algo antes que la petición se ha enviada
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // Haz algo con el error de la petición
  console.log({error})
  return Promise.reject(error);
});
