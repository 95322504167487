import { Button, Col, Modal, Row } from "antd";
import React from "react";
import { TituloModal } from "../../../../components/tituloModal/TituloModal";
import { formatCurrency } from "../../../../utils/formatCurrency";

export const ContenidoModal = ({ setOpen, open, desglose }) => {
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button danger onClick={() => setOpen(false)} size="small">
          cerrar
        </Button>,
      ]}
      title={<TituloModal texto="Datos Presupuestados" />}
    >
      {desglose.map((item, index) => (
        <Row key={index} className="rowItemElementoSmall">
          <Col span={16}>{item.tipoCosto}</Col>
          <Col span={8} style={{ textAlign: "right" }}>
            {formatCurrency(item.costo)}
          </Col>
        </Row>
      ))}
      <div style={{ marginTop: 5, fontWeight: 600, textAlign: "right" }}>
        Total:{" "}
        {formatCurrency(desglose.reduce((acc, item) => acc + item.costo, 0))}
      </div>
    </Modal>
  );
};
