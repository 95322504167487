import { Col, Row } from "antd";
import React from "react";
import { Accion } from "./Accion";
import { SubirEvidencia } from "./SubirEvidencia";
import { ListadoArchivos } from "./ListadoArchivos";

export const Controles = () => {
  return (
    <Row gutter={100}>
      <Col span={8}>
        <SubirEvidencia />
      </Col>
      <Col span={8}>
        <ListadoArchivos />
      </Col>
      <Col span={8}>
        <Accion />
      </Col>
    </Row>
  );
};
