import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startPostArribo } from "../../../../context/actions/asistenciasActions";
import { startUpdateServicioGnp } from "../../../../context/actions/gnpApiActions";
import { HDI_CLIENT_ID, HDI_FECHAS } from "../../../../constants/hdiConst";
import dayjs from "dayjs";
import { hdiClient } from "../../../../lib/axios/axiosClientSigsa";
import { ver } from "../../../../constants/url";

export const BotonArribo = () => {
  const dispatch = useDispatch();
  const {asistenciaID} = useParams();
  const {esGnpApi, folioGnp, numeroReporteHdi, asegurado} = useSelector(state => state.asistenciasReducer.asistencia)

  const handleArribo = async () => {

    if (asegurado.campania.clienteID === HDI_CLIENT_ID) {
      const fecha = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const body = {
        numeroReporteHdi,
        numeroReporteProveedor: asistenciaID,
        tipoFecha: HDI_FECHAS.fechaArribo,
        version: ver,
        cliente: 'proveedores',
        fecha,
        comentario: {
          comentario: 'Se ha realizado arribo', fecha
        }
      }
      const data = await hdiClient.put('notificacion', body)
      console.log({hdiData: data.data})
    }
    dispatch(startPostArribo(asistenciaID))

    if (esGnpApi) // confirmamos en api de gnp
      dispatch(startUpdateServicioGnp({asistenciaID, folioAsignacion: folioGnp, estatusActual: 'AR'}));
  }
  return (<div
    onClick={handleArribo}
    className="botonCirculo amarillo"
  >
    Arribar
  </div>);
};
