import { Col, Row } from "antd";
import React from "react";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { useGetOpData } from "./useGetOpData";

export const Tabla = ({}) => {
  const { items } = useGetOpData();
  return (
    <div style={{marginTop: 20}}>
      <Row className="">
        <Col span={3} className="column-title">
          AsistenciaID
        </Col>
        <Col span={2} className="column-title">
          Clave SAT
        </Col>
        <Col span={3} className="column-title">
          Servicio
        </Col>
        <Col span={6} className="column-title">
          Origen
        </Col>
        <Col span={2} className="column-title">
          Costo
        </Col>
        <Col span={2} className="column-title">
          IVA
        </Col>
        <Col span={2} className="column-title">
          IVAr
        </Col>
        <Col span={2} className="column-title">
          ISRr
        </Col>
        <Col span={2} className="column-title">
          Total
        </Col>
      </Row>

      {items.map((item, i) => (
        <Row key={i} className="">
          <Col span={3} className="data-field">
            {item.asistenciaID}
          </Col>
          <Col span={2} className="data-field">
            {item.claveSAT}
          </Col>
          <Col span={3} className="data-field">
           {item.servicio}
          </Col>
          <Col span={6} className="data-field">
            {item.ubicacion}
          </Col>
          <Col span={2} className="data-field" style={{ textAlign: "right" }}>
            {formatCurrency(item.monto)}
          </Col>
          <Col span={2} className="data-field" style={{ textAlign: "right" }}>
            {formatCurrency(item.iva)}
          </Col>
          <Col span={2} className="data-field" style={{ textAlign: "right" }}>
            {formatCurrency(item.ivaRetenido)}
          </Col>
          <Col span={2} className="data-field" style={{ textAlign: "right" }}>
            {formatCurrency(item.isrRetenido)}
          </Col>
          <Col span={2} className="data-field" style={{ textAlign: "right" }}>
            {formatCurrency(item.montoTotal)}
          </Col>
        </Row>
      ))}
    </div>
  );
};
