import { useState } from "react";
import { useDispatch } from "react-redux";
import { notification } from "../context/actions/notificationActions";
import { composeErrorNotification, composeSuccessNotification, } from "../utils/composeNotification";
import { postFile } from "../utils/fetch";

export const useUploadFile = (url, asistenciaID) => {
  const dispatch = useDispatch();
  const [ body, setBody ] = useState({});
  let formdata = new FormData();

  ///
  const camposEror = () => {
    let salida = false;
    if (estaVacioNull(body.file, "file")) salida = true;
    return salida;
  };

  ///
  const estaVacioNull = (valor, campo) => {
    console.log(campo, valor);
    return valor === "" || valor === null || valor === undefined;
  };

  ///
  const props = {
    beforeUpload: (file) => {
      setBody({...body, file});
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  ///
  const handleClick = async () => {
    if (camposEror()) {
      return;
    }

    formdata.append("file", body.file);
    formdata.append("filename", "File");
    formdata.append("mimetype", "application/pdf");
    formdata.append("AsistenciaID", asistenciaID);
    formdata.append("nombre", body.nombre);

    const result = await postFile(url, formdata);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));


      setBody({});
    } else {
      dispatch(
        notification(
          composeErrorNotification("Post Documento", `${result.error}`)
        )
      );
    }
  };

  return {body, props, setBody, handleClick};
};
