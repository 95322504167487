import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startPostCarretero } from "../../../../context/actions/asistenciasActions";

export const useForm = (asistencia) => {
  const dispatch = useDispatch();
  const [soloUnaSucursal, setSoloUnaSucursal] = React.useState(false);
  const [textoSucursal, setTextoSucursal] = React.useState("");
  const [arrayItem, setArrayItem] = React.useState({});
  const [arrayFull, setArrayFull] = React.useState([]);
  const [body, setBody] = React.useState({
    ProveedorPropuestoID: asistencia.proveedorPropuestoID,
    costoPropuesto: 0,
  });

  const { sucursales } = useSelector(
    (state) => state.proveedorInfoReducer.proveedorInfo
  );

  //
  useEffect(() => {
    if (sucursales && sucursales.length === 1) {
      setSoloUnaSucursal(true);
      setTextoSucursal(sucursales[0].nombre);
      setBody({
        ...body,
        proveedorSucursalID: sucursales[0].proveedorSucursalID,
      });
    }
  }, [sucursales]);

  //
  const handleChange = ({ target }) =>
    setBody({ ...body, [target.name]: Number(target.value) });


  //
  const changeSelect = (val, ele) =>
    setBody({ ...body, proveedorSucursalID: val });

  //
  const handleOk = () =>
    dispatch(
      startPostCarretero({
        ...body,
        desgloseItems: arrayFull,
      })
    );

  const addElementToArrayItem = (val, ele) => {
    setArrayItem({
      ...arrayItem,
      [ele]: val,
    });
  };

  const addToArrayFull = async () => {
    if (arrayItem.costo < 20) return;
    setArrayFull([...arrayFull, arrayItem]);
    setArrayItem({});
    setBody({ ...body, costoPropuesto: body.costoPropuesto + arrayItem.costo });
  };
  const arrayDisabled = Object.keys(arrayItem).length !== 2;

  const eliminar = (idex) => {
    const obj = arrayFull[idex];
    const newArray = arrayFull.filter((item, index) => index !== idex);
    setBody({ ...body, costoPropuesto: body.costoPropuesto - obj.costo });
    setArrayFull(newArray);
  };
  //
  return {
    soloUnaSucursal,
    textoSucursal,
    handleOk,
    handleChange,
    changeSelect,
    addElementToArrayItem,
    arrayDisabled,
    addToArrayFull,
    arrayItem,
    body,
    arrayFull,
    eliminar,
  };
};
