import { Col, Row } from "antd";
import React from "react";

export const TablaHeader = () => {
  return (
    <Row gutter={10} style={{ marginBottom: 15 }}>
      <Col span={2} className="tituloTabla">
        ID
      </Col>
      <Col span={2} className="tituloTabla">
        Capturado
      </Col>
      <Col span={2} className="tituloTabla">
        Asignado
      </Col>
      <Col span={2} className="tituloTabla">
        Arribo
      </Col>
   
      <Col span={4} className="tituloTabla">
        Servicio
      </Col>
      <Col span={4} className="tituloTabla">
        Origen
      </Col>
      <Col span={4} className="tituloTabla">
        Destino
      </Col>
      <Col span={2} className="tituloTabla">
      Estatus
      </Col>
  
    </Row>
  );
};
