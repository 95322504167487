import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import RowAsistencia from "./RowAsistencia";

/////
export const TableBody = () => {
  const { asistenciasVivas } = useSelector((state) => state.asistenciasReducer);

  if (asistenciasVivas.length === 0)
    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>Sin servicios...</div>
    );

  ///////////////////////////////////
  return (
    <>
      {asistenciasVivas.map((a, i) => {
        return <RowAsistencia a={a} key={i} />;
      })}
    </>
  );
};
