import { Col } from "antd";
import { LabelValor } from "../../../../components/label/LabelValor";

export const SplitCampo = ({ campo }) => {
  if (campo === null || campo === "" || campo === undefined) return <></>;
  const arr = campo.split(":");

  if(arr[0] == "Placas" || arr[0] == "placas") return <></>  

  return (
    <Col span={6}>
      <LabelValor label={arr[0]} valor={arr[1]} />
    </Col>
  );
};
