import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectSucursales } from "../../../../components/select/SelectSucursales";
import { setConfirmaAsistenciaData } from "../../../../context/actions/asistenciasActions";

export const FormaAceptarServicio = ({ setData }) => {
  const [soloUnaSucursal, setSoloUnaSucursal] = React.useState(false);
  const [textoSucursal, setTextoSucursal] = React.useState("");

  const { sucursales } = useSelector(
    (state) => state.proveedorInfoReducer.proveedorInfo
  );

  const dispatch = useDispatch();
  const changeSelect = (proveedorSucursalID) => {
    dispatch(setConfirmaAsistenciaData({ proveedorSucursalID }));
  };

  useEffect(() => {
    dispatch(setConfirmaAsistenciaData({ tiempoLlegada: 60 }));
  }, [dispatch]);

  useEffect(() => {
    if (sucursales.length === 1) {
      setSoloUnaSucursal(true);
      setTextoSucursal(sucursales[0].nombre);
      dispatch(
        setConfirmaAsistenciaData({
          proveedorSucursalID: sucursales[0].proveedorSucursalID,
        })
      );
    }
  }, [sucursales]);

  return (
    <div>
      <Row gutter={10}>
        <Col span={12}>
          {soloUnaSucursal ? (
            <div style={{fontWeight: 600, textAlign: 'center', marginTop: 10}}>Sucursal: <br />{textoSucursal}</div>
          ) : (
            <SelectSucursales
              label="Sucursal encargada"
              onChange={changeSelect}
            />
          )}
        </Col>
        <Col span={12}>
          <div style={{ fontWeight: 600, padding: 10, color: "#ca2228", textAlign: "center" }}>
            Acepto llegar <br />
            en menos de 60 minutos
          </div>
        </Col>
      </Row>
    </div>
  );
};
