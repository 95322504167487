import { Button } from "antd";
import React, { useState } from "react";
import { ContenidoModal } from "./_ContenidoModal";

export const ModalPropuesta = ({ desglose }) => {
  const [open, setOpen] = useState(false);

  if (!desglose) return <></>;
  if (desglose.length === 0) return <></>;
  return (
    <>
      <Button onClick={() => setOpen(true)} size="small" danger type="default">
        $ Cotización
      </Button>
      <ContenidoModal setOpen={setOpen} open={open} desglose={desglose} />
    </>
  );
};
