import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { startPostConcluido } from "../../../../context/actions/asistenciasActions";
import { startUpdateServicioGnp } from "../../../../context/actions/gnpApiActions";
import dayjs from "dayjs";
import { HDI_CLIENT_ID, HDI_FECHAS } from "../../../../constants/hdiConst";
import { hdiClient } from "../../../../lib/axios/axiosClientSigsa";
import { ver } from "../../../../constants/url";

export const BotonConlcuido = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {asistenciaID} = useParams();
  const {esGnpApi, folioGnp, numeroReporteHdi, asegurado} = useSelector(state => state.asistenciasReducer.asistencia)

  //
  const concluir = async () => {


    if (asegurado.campania.clienteID === HDI_CLIENT_ID) {
      const fecha = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const body = {
        numeroReporteHdi,
        numeroReporteProveedor: asistenciaID,
        version: ver,
        cliente: 'proveedores',
        tipoFecha: HDI_FECHAS.fechaTermino,
        fecha,
        comentario: {
          comentario: 'Se ha concluido el servicio', fecha
        }
      }
      const data = await hdiClient.put('notificacion', body)
      console.log({dataHDI: data.data})
    }

    dispatch(startPostConcluido(asistenciaID));
    if (esGnpApi) // confirmaos en api de gnp
      dispatch(startUpdateServicioGnp({folioAsignacion: folioGnp, estatusActual: 'TE'}));
    history.push("/");
  };

  //
  return (
    <div onClick={concluir} className="botonCirculo verde">
      Concluir
    </div>
  );
};
