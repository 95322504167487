import { Col, Divider, Row } from "antd";
import React from "react";
import { useGetOpData } from "./useGetOpData";
import { formatCurrency } from "../../../../utils/formatCurrency";

export const Header = ({ ordenPagoID }) => {
  const { headerbody } = useGetOpData(ordenPagoID);

  return (
    <div style={{ border: "1px solid #D4D4D4" }}>
      <Row>
        <Col xs={24} xl={4} className="tituloCampo">
          Facturar a
        </Col>
        <Col xs={24} xl={20} className="data-field">
          Escotel especialistas en contacto telefónico SA de CV{" "}
          <b>EEC-081222-FH8</b>
          <br />
          José María Velasco 13 oficina 301, col. San José Insurgentes C.P.
          03900, CDMX
        </Col>

        <Col xs={24} xl={4} className="tituloCampo">
          Método de pago
        </Col>
        <Col xs={24} xl={20} className="data-field">
          03 transferencia electrónica de fondo ó 99 por definir
        </Col>
        <Col xs={24} xl={4} className="tituloCampo">
          Clave de uso
        </Col>
        <Col xs={24} xl={8} className="data-field">
          CFDI G01 - Adquisición de Mercancías
        </Col>
        <Col xs={24} xl={4} className="tituloCampo">
          Forma de pago
        </Col>
        <Col xs={24} xl={8} className="data-field">
          PPD (Pago en parcialidades o deferido)
        </Col>

        <Col xs={4} className="tituloCampo">
          Servicios
        </Col>
        <Col xs={8} className="data-field" style={{ textAlign: "center" }}>
          {headerbody.servivicios}
        </Col>

        <Col xs={4} className="tituloCampo">
          IVA
        </Col>
        <Col xs={8} className="data-field" style={{ textAlign: "center" }}>
          {formatCurrency(headerbody.iva)}
        </Col>

        <Col xs={4} className="tituloCampo">
          Subtotal
        </Col>
        <Col xs={8} className="data-field" style={{ textAlign: "center" }}>
          {formatCurrency(headerbody.subtotal)}
        </Col>

        <Col xs={4} className="tituloCampo">
          IVA retenido
        </Col>
        <Col xs={8} className="data-field" style={{ textAlign: "center" }}>
          {formatCurrency(headerbody.ivaRetenido)}
        </Col>

        <Col xs={4} className="tituloCampo">
          Total
        </Col>
        <Col xs={8} className="data-field" style={{ textAlign: "center" }}>
          {formatCurrency(headerbody.total)}
        </Col>

        <Col xs={4} className="tituloCampo">
          ISR retenido
        </Col>
        <Col xs={8} className="data-field" style={{ textAlign: "center" }}>
          {formatCurrency(headerbody.isrRetenido)}
        </Col>
      </Row>
    </div>
  );
};
