import { Tabs } from "antd";
import { Ordenes } from "./ordenes/Ordenes.jsx"
import { Servicios } from "./servicios/Servicios.jsx"
import { Instrucciones } from "./instrucciones/Instrucciones.jsx";
import { useValidaProveedorFacturacion } from "../../hooks/useValidaProveedorFacturacion.js";


export const Facturacion = () => {

  const { canFactuacion } = useValidaProveedorFacturacion();


    const items = [
        {
            key: '1',
            label: 'Servicios Pendientes',
            children: <Servicios />,
        },
        {
            key: '2',
            label: 'Ordenes de Pago Pendientes' ,
            children: <Ordenes />,
        },
        {
            key: '3',
            label: 'Instrucciones de Facturación' ,
            children: <Instrucciones />,
        },

    ];

    if(!canFactuacion) return <></>

    return (<Tabs defaultActiveKey="1" items={items} />)
}
