import { add, compareAsc } from "date-fns"

export const noPresentarAun = (fechaSugiereProveedor, RetrasoAsgigAuto) => {
    const ahora = new Date();
    const fechaPresentar = add(new Date(fechaSugiereProveedor), {
        minutes: Number(RetrasoAsgigAuto),
    })

    const resultado = compareAsc(ahora, fechaPresentar)
    return resultado === -1 // si ahora es menor que presentar
}
