import { URL_GENERICOS } from "../../constants/url";
import { URL_GNP_POST_SERVICIO } from "../../constants/urlGnp";
import { GNP_POST } from "../../utils/fetch";




/////////////////////////

export const startUpdateServicioGnp = ({ asistenciaID, folioAsignacion, estatusActual }) => {
  return async (dispatch) => {
    const body = {
      nombrePrestadorServicio: "Escotel",
      telefonoPrestadorServicio: "5588805516",
      especialidad: "Plataforma",
      idMotivo: "CACL",
      comentarios: "",
      fechaHoraEstatus: Date.now(),
      estatusActual,
      folioAsignacion
    }
    console.log("enviando...")
    console.log({ body })
    console.log({ URL_GNP_POST_SERVICIO })
    const resultado = await GNP_POST(URL_GNP_POST_SERVICIO, body);

    if (resultado.code !== -1)
      console.log(resultado)
    else {
      const data = {
        body: JSON.stringify(body),
        asistenciaID,
        result: JSON.stringify(resultado.error)
      }
      dispatch(startCatchError(data))
    }


  }
}

const startCatchError = (data) => {
  return async () => {

    const url = `${URL_GENERICOS}/CatchError`
    const resultado = await GNP_POST(url, data);
    if (resultado.code !== -1)
      console.log("Error enviado")
    else {
      console.log("Error de post startCatchError")

    }

  }
}