import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetAsistenciasPorConfirmar } from "../../../context/actions/asistenciasActions";
import CardPage from "../../../layout/CardPage";
import { TablaHeader } from "./_TablaHeader";
import { TableBody } from "./_TableBody";

export const AsistenciasPorConfirmar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetAsistenciasPorConfirmar());

    const timer = setInterval(() => {
      dispatch(startGetAsistenciasPorConfirmar());
    }, 1000 * 60 * 0.5); // 30seg 

    return () => clearInterval(timer);
  }, [dispatch]);

  return (
    <CardPage titulo="Servicios por Aceptar">
      <TablaHeader />
      <TableBody />
    </CardPage>
  );
};
