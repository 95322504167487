import React from "react";
import { useSelector } from "react-redux";
import { BotonArribo } from "./BotonArribo";
import { BotonConlcuido } from "./BotonConlcuido";
import { SinConfirmar } from "./SinConfirmar";

export const Accion = () => {
  const { estatusID } = useSelector(
    (state) => state.asistenciasReducer.asistencia
  );

  if (estatusID <= 3) return <BotonArribo />;
  if (estatusID === 4) return <SinConfirmar />;
  return <BotonConlcuido />;
};
