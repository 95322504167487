import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useGetOpData = () => {
  const [headerbody, setHeaderBody] = useState({});
  const { ordenPagoFull } = useSelector((state) => state.facturacionReducer);

  useEffect(() => {
    headerData();
  }, []);

  const headerData = () => {
    const { items } = ordenPagoFull;
    const servivicios = items.length;
    const subtotal = items.reduce((acc, item) => acc + item.monto, 0);
    const iva = items.reduce((acc, item) => acc + item.iva, 0);
    const ivaRetenido = items.reduce((acc, item) => acc + item.ivaRetenido, 0);
    const isrRetenido = items.reduce((acc, item) => acc + item.isrRetenido, 0);
    const total = items.reduce((acc, item) => acc + item.montoTotal, 0);

    setHeaderBody({
      servivicios,
      subtotal,
      iva,
      ivaRetenido,
      isrRetenido,
      total,
    });
  };

  return { headerbody, items: ordenPagoFull.items };
};
