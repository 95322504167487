import { types } from "../types/types";

const init = {
  serviciosPorValidar: [],
  serviciosPorGenerarOp: [],
  serviciosRechazados: [],
  detailsFactura: {},
  ordenesAbiertas: [],

  isValidVersion: true,
  showModal: "show",

  loadingCrearOP: false,
  ordenPagoFull: {},
  loadingOp: true,
};

export const facturacionReducer = (state = init, action) => {
  switch (action.type) {

    case types.setLoadingOp:
      return {
        ...state,
        loadingOp: action.payload,
      };

    case types.storeOrdenPagoFull:
      return {
        ...state,
        ordenPagoFull: action.payload,
      };
    case types.setLoadingCrearOP:
      return {
        ...state,
        loadingCrearOP: action.payload,
      };

    case types.storeOrdenesAbiertas:
      return {
        ...state,
        ordenesAbiertas: action.payload,
      };

    case types.storeServiciosPorValidar:
      return {
        ...state,
        serviciosPorValidar: action.payload,
      };

    case types.storeServiciosPorGenerarOp:
      return {
        ...state,
        serviciosPorGenerarOp: action.payload,
      };

    case types.storeServiciosRechazados:
      return {
        ...state,
        serviciosRechazados: action.payload,
      };

    case types.handleModalVersion:
      return {
        ...state,
        isValidVersion: action.payload,
      };
    case types.showModal:
      return {
        ...state,
        showModal: action.payload,
      };

    default:
      return state;
  }
};
