import { URL_BASE_ACCESOPROVEEDORES } from "../../constants/url";
import { composeErrorNotification, } from "../../utils/composeNotification";
import { get } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const GetProveedorInfo = () => {
  return async (dispatch) => {
    const url = `${URL_BASE_ACCESOPROVEEDORES}/GetProveedorInfo`;

    const response = await get(url);

    if (response.code !== -1) {
      dispatch(storeProveedorInfo(response.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetProveedorInfo", response.error)
        )
      );
    }
  };
};

const storeProveedorInfo = (info) => ({
  type: types.storeProveedorInfo,
  payload: info,
});
  