import { FilePdfOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { dateFromString } from "../../../utils/dateFromString";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { startDownloadDocumentoOP } from "../../../context/actions/facturacionActions";

export const Comprobante = ({ orden }) => {
  const dispatch = useDispatch();

  const handleClick = () =>
    dispatch(startDownloadDocumentoOP(orden.ordenPagoID, 1)); // Comprobante de pago

  return (
    <>
      {dateFromString(orden.fechaPagado).soloFecha}
      {orden.pagoUri ? (
        <Tooltip title="Descargar Comprobante de Pago">
          <FilePdfOutlined
            onClick={handleClick}
            className="eyeIcon"
            style={{ marginLeft: 5, fontSize: 16, color: "orange" }}
          />
        </Tooltip>
      ) : orden.fechaPagado && (<Tooltip title="Aun no se carga comprobante de pago">
        <QuestionCircleOutlined style={{color:'orange', marginLeft: 3}}  />
      </Tooltip>)}
    </>
  );
};
