import React from 'react'
import { URL_SELFSERVICE_SERVICIOS } from '../../../../constants/url'
import { postFile } from '../../../../utils/fetch'
import { useDispatch } from 'react-redux'
import { notification } from '../../../../context/actions/notificationActions'
import { composeErrorNotification, composeSuccessNotification } from '../../../../utils/composeNotification'
import { startGetServiciosPorValidar, startGetServiciosPorGenerarOp } from '../../../../context/actions/facturacionActions'

export const usePostFile = () => {
    const dispatch = useDispatch()
    const [body, setBody] = React.useState({})

    const onClick = async () => {
        setBody({ ...body, loading: true })
        
        var formData = new FormData()
        formData.append('file', body.file)

        const url = `${URL_SELFSERVICE_SERVICIOS}/PostServiciosPorValidar`
        const response = await postFile(url, formData);

     
        if (response.code === 0) {
            dispatch(notification(composeSuccessNotification( response.data, 15)))
            dispatch(startGetServiciosPorValidar())
            dispatch(startGetServiciosPorGenerarOp())

        } else {
            dispatch(notification(
                composeErrorNotification(response.error)
            ))
        }
        setBody({ ...body, loading: false })

    }
    return { body, setBody, onClick }
}
