import { useDispatch, useSelector } from "react-redux";
import { Header } from "./_Header";
import { Tabla } from "./_Tabla";
import { useEffect } from "react";
import { startGetOrdenPagoByID } from "../../../../context/actions/facturacionActions";
import { Skeleton } from "antd";

export const ContenidoModal = ({ ordenPagoID }) => {
  const dispatch = useDispatch();
  const { loadingOp } = useSelector((state) => state.facturacionReducer);

  useEffect(() => {
    dispatch(startGetOrdenPagoByID(ordenPagoID));
  }, [dispatch, ordenPagoID]);

  if (loadingOp) return <Skeleton active />;
  return (
    <div style={{ paddingBottom: 30 }}>
      <Header />
      <Tabla />
    </div>
  );
};
