import React from 'react'

export const SinConfirmar = () => {
    return (

            <div className='sinConfirmar'>
                No hemos confirmado el servicio <br />
                Una vez confirmado podrá marcarlo
                como concluido
            </div>
           
    )
}
