import { types } from "../types/types";

const notificationInitial = {
  notification: {
    message: "",
    description: "",
    type: "",
    dateTime: "",
  },
  versionBackend: "x",
};

export const notificationReducer = (state = notificationInitial, action) => {
  switch (action.type) {
    case types.storeVersionBackend:
      return {
        ...state,
        versionBackend: action.payload,
      };
    case types.notification:
      return {
        ...state,
        notification: action.payload,
      };

    default:
      return state;
  }
};
