import { asistenciasReducer } from "../reducers/asistenciasReducer";
import { authErrReducer } from "../reducers/authErrReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { proveedorInfoReducer } from "../reducers/proveedorInfoReducer";
import { userReducer } from "../reducers/userReducer";
import { facturacionReducer } from "../reducers/facturacionReducer";

export const allReducers = {
  notificationReducer: notificationReducer,
  userReducer: userReducer,
  authErrReducer: authErrReducer,
  asistenciasReducer: asistenciasReducer,
  proveedorInfoReducer: proveedorInfoReducer,
  facturacionReducer,
};
