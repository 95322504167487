import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PROVEEDORES_FACTURACION } from "../constants/cons";

export const useValidaProveedorFacturacion = () => {
  const { proveedorID } = useSelector((state) => state.userReducer.user);
  const [canFactuacion, setCanFacturacion] = useState(false);

  useEffect(() => {
    if(!proveedorID) return; 
    
    if (PROVEEDORES_FACTURACION.includes(proveedorID)) {
      setCanFacturacion(true);
    } else {
      setCanFacturacion(false);
    }
  }, [proveedorID]);

  return { canFactuacion };
};
