export const HDI_FECHAS = {
  fechaAsignacion: 17,
  fechaArribo: 18,
  fechaTermino: 19,
  fechaCancelado: 20,
  fechaCita: 21
}


export const HDI_CLIENT_ID = 'D2pceuqnx8'
