import React from "react";
import ModalPage from "../../../../layout/ModalPage";
import { useDispatch } from "react-redux";
import { startConfirmarProveedor } from "../../../../context/actions/asistenciasActions";
import { FormaAceptarServicio } from "./_FormaAceptarServicio";

export const ModalLocal = ({ open, setOpen, asistencia }) => {
  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch(startConfirmarProveedor(asistencia.asistenciaID, asistencia));

    if (asistencia.esGnpApi)
      // confirmaos en api de gnp
      dispatch({
        folioAsignacion: asistencia.folioGnp,
        estatusActual: "EA",
      });
    setOpen(false);
  };


  return (
    <ModalPage
      handleOk={handleOk}
      handleCancel={() => setOpen(false)}
      isVisible={open}
      titulo="Tomar Servicio - LOCAL"
      okLabel="Aceptar Servicio"
    >
      <FormaAceptarServicio />
    </ModalPage>
  );
};
