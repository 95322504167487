import { Spin } from "antd";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  startGetArchivos,
  startGetAsistencia,
} from "../../../context/actions/asistenciasActions";

import { DatosAsistencia } from "./datosAsistencia/DatosAsistencia";
import { Mapa } from "./mapa/Mapa";

export const Detalle = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();

  const { cargandoAsistencia } = useSelector(
    (state) => state.asistenciasReducer
  );

  ///
  useEffect(() => {
    dispatch(startGetAsistencia(asistenciaID));
    dispatch(startGetArchivos(asistenciaID));
  }, []);

  //////
  return (
    <>
      <DatosAsistencia />
      <Mapa />
    </>
  );
};
