import { Button, Col, Input, Row, Select, Tooltip } from "antd";
import ModalPage from "../../../../layout/ModalPage";
import LabelNumber from "../../../../components/input/LabelNumber";
import { SelectSucursales } from "../../../../components/select/SelectSucursales";
import { useForm } from "./useForm";
import { opcionesDesgloseCarretero } from "../../../../constants/cons";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ListaItems } from "./_ListaItems";

export const ModalCarretero = ({ open, setOpen, asistencia }) => {
  const {
    soloUnaSucursal,
    textoSucursal,
    handleOk,
    handleChange,
    changeSelect,
    addElementToArrayItem,
    arrayDisabled,
    addToArrayFull,
    arrayItem,
    body,
    arrayFull,
    eliminar,
  } = useForm(asistencia);

  const submit = () => {
    handleOk();
    setOpen(false);
  };

  //
  return (
    <ModalPage
      handleOk={submit}
      handleCancel={() => setOpen(false)}
      isVisible={open}
      titulo="Tomar Servicio - CARRETERO"
      okLabel="Aceptar Servicio"
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {soloUnaSucursal ? (
            <div
              style={{ fontWeight: 600, textAlign: "center", marginTop: 10 }}
            >
              Sucursal:
              {textoSucursal}
            </div>
          ) : (
            <SelectSucursales
              label="Sucursal encargada"
              onChange={(val) => changeSelect(val, "proveedorSucursalID")}
            />
          )}
        </Col>
        <Col span={12}>
          <LabelNumber
            onChange={handleChange}
            name="costoPropuesto"
            label="Costo Propuesto"
            value={body.costoPropuesto}
            disabled
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            onChange={handleChange}
            name="tiempoLlegadaPropuesto"
            label="Tiempo de Arribo"
          />
        </Col>
        <Col span={24}>
          <Row gutter={5}>
            <Col span={16}>
              <div className="labelImput">Descripción de Costos</div>
              <Select
                style={{ width: "100%" }}
                onChange={(val) => addElementToArrayItem(val, "tipoCosto")}
                options={opcionesDesgloseCarretero}
                placeholder="Seleccione una opción"
                value={arrayItem.tipoCosto}
              />
            </Col>
            <Col span={6}>
              <LabelNumber
                onChange={({ target }) =>
                  addElementToArrayItem(Number(target.value), "costo")
                }
                name="tiempoLlegadaPropuesto"
                label="Costo"
                value={arrayItem.costo}
              />
            </Col>
            <Col span={2}>
              <Tooltip title="Agregar Costo">
                <Button
                  disabled={arrayDisabled}
                  style={{ marginTop: 20 }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={addToArrayFull}
                ></Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>

      <ListaItems arrayFull={arrayFull} eliminar={eliminar} />
    </ModalPage>
  );
};
