import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import LabelText from "../../../../components/input/LabelText";
import LabelSelect from "../../../../components/select/LabelSelect";
import { URL_BASE, URL_BASE_ACCESOPROVEEDORES } from "../../../../constants/url";
import { startGetArchivos } from "../../../../context/actions/asistenciasActions";
import { useUploadFile } from "../../../../hooks/useUploadFile";
import { post } from "../../../../utils/fetch";

const opciones = [
  { texto: 'lugar', valor: 'lugar' },
  { texto: 'placas', valor: 'placas' },
  { texto: 'confirmacion', valor: 'confirmacion' },
]

export const SubirEvidencia = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const url = `${URL_BASE_ACCESOPROVEEDORES}/PostDocumento`;
  const { body, setBody, handleClick } = useUploadFile(url, asistenciaID); // 0 = factura
  const userId = useSelector(state => state.userReducer.user.usuarioID)


  const props = {
    beforeUpload: (file) => {
      setBody({ ...body, file });
      console.log(body);
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const handleChangeSelect = (val) => {
    setBody({ ...body, nombre: val });
  };

  const envia = async () => {
    if (body.nombre === 'placas') {
      const formData = new FormData();
      formData.append('file', body.file);
      formData.append('usuarioId', userId)
      formData.append("asistenciaId", asistenciaID)
      await post(`${URL_BASE}/api/asistencias/postPlaca`, formData)
    }

    handleClick().then(() => dispatch(startGetArchivos(asistenciaID)));
  };


  return (
    <div>
      <div className="subtituloMenos">Subir evidencia</div>
      <div >
        <LabelSelect
          opciones={opciones}
          label="Nombre"

          value={body.nombre}
          onChange={handleChangeSelect}
        />
      </div>
      <div>
        <Upload {...props} style={{ width: "100%" }}>
          <Button
            style={{ textAlign: "left", marginTop: 20 }}
            icon={<UploadOutlined />}
          >
            Seleccione documento
          </Button>
        </Upload>
      </div>
      <div style={{ height: 20 }}>{body.file?.name}</div>
      <div>
        <BotonDivL tituloBoton="Agregar" onClick={envia} />
      </div>
    </div>
  );
};
